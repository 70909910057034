html{
  font-size: 62.5% !important;  /* 16px * 62.5 =10px = 1rem*/ 
  box-sizing: border-box;
}
body{
    font: 1.6rem Helvetica !important;
    height: 100vh;
    margin: 0;
}
a{
    text-decoration: none !important;
}
a:hover{
    color: orange;
}
.div-container{
    display: grid;
    grid-template-areas: 
    "header"
    "main"
    "footer"
    ;

    grid-template-columns: 1fr;
    grid-template-rows: 5rem 1fr 5rem;
    height: 100%;
}
.header{
    grid-area: header;
    background-color: #203050;
    color:white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.brand a{
    color: #fff;
    font-weight: bold;
    font-size: 2.5rem !important;
    text-decoration: none;
}
.header a{
    color: white;
    margin-right: 2rem;
    text-decoration: none;
}

.header a:hover{
    color:orange;
}
.main{
    grid-area: main;
}
.footer{
    grid-area: footer;
    display: flex;
    background-color: #203050;
    color: white;
    justify-content: center;
    align-items: center;
}

/* Home Screen */
.products{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.products li{
    list-style-type: none;
    padding: 0;
    flex: 0 1 34rem;
    margin: 1rem;
    height: 50rem;
    border-bottom: .1rem #c0c0c0 solid;
}
.product{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.product-name{
    font-size: 2rem !important;
    font-weight: bold !important;
    text-decoration: none;
}

.product-brand{
    font-size: 1.5rem !important;
    color: #808080;
}
.product-price{
    font-size: 2.5rem !important;
    font-weight: bold;
}

.product-image{
    max-width: 34rem;
    max-height: 34rem;
}
.product-rating{
    margin-bottom: 1rem;
}

/* Sidebar */
.brand button{
    font-size: 3rem !important;
    padding: .5rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}
.sidebar{
    transition: all .5s ease;
    position: fixed;
    transform: translateX(-30rem);
    width: 30rem;
    background-color: rgb(228, 228, 228);
    max-width: 20%;
    width: 30rem;
    height: 100vh;
}
.sidebar.open{
    transform: translateX(0)
}
.sidebar button{
    background: none;
    color: black;
    border: none;
    font-size: 3.5rem !important;
    position: fixed;
    top: 0;
    right: 0;
    margin: 1.2rem 1rem;
    cursor: pointer;
}
.sidebar h3{
    color: black;
    font-size: 2rem !important;
    padding: 0 10px;
}
.sidebar ul li{
    list-style-type: none;
    display: block;
    padding: 10px 8px;
}
.sidebar ul li a{
  color: black;
  font-weight: 500 !important;
  display: block;
}
.sidebar ul li:hover{
  background-color:orange;
  color: white ;
}
#root{
    height: 100%;
}


/* PRODUCT DETAILS */
.back-home-btn{
    margin: 3rem 10px;
}
.back-home-btn a{
    border-radius: 50px;
    padding: 10px ;
    background-color:#203050;
    color: white;
}
.row{
    display:  flex;
    justify-content: space-between;
}
.about-product li , .product-action li{
    list-style: none;
}
.details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}
.details .product-image img{
    width: 300px;   
}
.details .about-product ul{
    padding: 0;
}

.details .about-product ul li {
    margin: 10px;
}
.details .product-action{
    padding: 15px 15px;
    border-radius: 10px;
    width: 20%;
}
.action-btn{
   display: block;
   width: 100%;
   padding: 10px;
   border-radius: 10px; 
   font-size: 16px !important;
   background-color: orange;
   border: none;
   cursor: pointer;
}
.details .product-action ul li{
    margin: 15px 5px;
}
/* CART SCREEN */
.cart-action{
    background: whitesmoke;
    padding: 3rem 1.5rem;
    border-radius: 10px;
    width:fit-content;
}
.cart-action div h2 span{
    display: inline-flex;
    flex-wrap: nowrap;
    white-space:nowrap;
    width: auto;
}
table , tr , td ,th{
    border-bottom: 1px solid #000;
}
table , tr , td{
    font-weight: 300;
    margin-top: auto;
}

.product-info{
    margin-top: auto;
}